<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingBusiness">
              <ValidationObserver
                v-if="business"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateBusiness)"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <TextInput
                          v-model="business.business_name"
                          name="business_name"
                          rules="required"
                          label="Business Name"
                      />
                    </div>
                    <div class="col-lg-5">
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.address"
                              name="business_address"
                              rules="required"
                              label="Address"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.city"
                              name="business_city"
                              rules="required"
                              label="City"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row">
                        <div class="col-md-5">
                          <TextInput
                              v-model="business.state"
                              name="business_state"
                              rules="required"
                              label="State"
                          />
                        </div>
                        <div class="col-md-7">
                          <TextInput
                              v-model="business.zip"
                              name="business_zip"
                              rules="required"
                              label="Zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-4">
                          <TextInput
                              v-model="business.business_phone"
                              v-mask="'(###) ###-####'"
                              name="business_phone"
                              rules="required|phone_number"
                              label="Business Phone"
                          />
                        </div>
                        <div class="col-md-8">
                          <TextInput
                              v-model="business.business_website"
                              name="business_website"
                              rules="required"
                              label="Website URL"
                          ><a :href="business.business_website" target="_blank" class="btn btn-dark-blue link-target align-middle"><Feather class="link-eye" type="eye" /></a></TextInput>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.date"
                                name="date"
                                label="Date Created"
                                disabled
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.types"
                                name="categories"
                                label="Primary Category"
                            />
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row btn-links">
                        <div class="col-sm-6">
                          <label class="d-block">Review Link</label>
                          <a class="btn btn-dark-blue form-control" target="_blank" :href="'https://reviewour.biz/r/' + business.unique_id"><span>View Business Review Link</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                        </div>
                        <div class="col-sm-6">
                          <label class="d-block">Maps Link</label>
                          <a class="btn btn-dark-blue form-control" target="_blank" :href="business.google_maps_url"><span>View Business On Maps</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 mt-lg-0 mt-md-3 mt-sm-3">
                      <div class="d-inline-flex align-items-center">
                        <TextInput
                            v-model="business.unique_id"
                            name="unique_id"
                            rules="required|subdomain"
                            label="Landing Page"
                            linkstart="https://reviewour.biz/r/"
                        ><button type="button" class="btn btn-dark-blue btn-sm copy-link" @click="copyLink"><Feather type="copy" /></button></TextInput>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <TextInput
                          v-model="business.origin"
                          name="origin"
                          label="Originating URL"
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                        v-model="business.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                        v-model="business.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Phone Number</label>
                      <vue-tel-input
                          v-model="business.phone_number"
                          class="form-control"
                          :input-options="{ showDialCode: false, tabindex: false }"
                          rules="required|phone"
                      ><template v-slot:arrow-icon>
                        <span>{{ open ? '▲' : '▼' }}</span>
                      </template></vue-tel-input>
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                          v-model="business.email"
                          name="owner.email"
                          rules="required|email"
                          label="Email"
                      />
                    </div>
                  </div>
                  <b-form-group class="text-right">
                    <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      countries: [{ key: 'US', label: 'United States of America' }, { key: 'CA', label: 'Canada' }],
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    onlyCountries() {
      return [ this.business.country ]
    },

    customLink(){
      return 'https://reviewour.biz/r/'+this.business.unique_id;
    }
  },

  mounted() {
    this.getBusiness()
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.customLink);
      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    updateBusiness() {
      this.loadingUpdate = true

      this.$store
        .dispatch('business/update', this.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getBusiness() {
      this.loadingBusiness = true

      this.$store
        .dispatch('business/find', this.$route.params.id)
        .then((business) => {
          business.message_limit = business.no_messages != null
          business.contact_limit = business.no_contacts != null
          this.business = business
          this.loadingBusiness = false
        })
        .catch(() => {
          this.$router.push({ name: 'agency.businesses.index' })
          this.loadingBusiness = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import url('https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css');
  i.feather--eye{
    margin-top: -3px;
  }
  .btn-links .btn{
    white-space: nowrap;
   span{
      vertical-align: top;
    }
  }
  input[name="unique_id"] {
    max-width: 150px !important;
  }
  .copy-link, .link-target{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .link-target{
    max-height: 39px;
  }
</style>
